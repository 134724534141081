<template>
  <div class="document-request-from-show-step2">
    <RequestVsVm :text="requestForCorporate.text || ''" />
    <MainFormCorporateShow :type="type"/>
    <FacilityInterestedShow :type="type" />
    <FindAboutVacationShow :type="type" />
  </div>
</template>

<script>
import MainFormCorporateShow from '@/components/documentRequest/corporateCustomer/MainFormCorporateShow'
import FacilityInterestedShow from '@/components/common/ShowDataStep2/FacilityInterestedShow'
import FindAboutVacationShow from '@/components/common/ShowDataStep2/FindAboutVacationShow'
import RequestVsVm from '../common/RequestVsVm.vue'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  components: {
    MainFormCorporateShow,
    FacilityInterestedShow,
    FindAboutVacationShow,
    RequestVsVm
  },
  mounted () {
  },
  computed: {
    requestForCorporate () {
      return this.$store.state.documentRequest.dataCorporateCustomer.requestForCorporate
    }
  }
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
