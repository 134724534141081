<template>
  <v-card>
    <v-form v-model="isValid" ref="form">
      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">会社名</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">例：東急ホテルズ&amp;リゾーツ株式会社</label>
          </div>
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                class="mr-6"
                required
                label=""
                hide-details="auto"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.companyName"
                :rules="companyNameRules"
                solo
              />
            </v-col>
          </v-row>
        </div>
      </v-row>
      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">会社名フリガナ</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">例：トウキュウホテルズアンドリゾーツ カブシキガイシャ</label>
          </div>
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                required
                label=""
                class="mr-6"
                hide-details="auto"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.companyNameKana"
                :rules="kanaCompanyNameRules"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">業種</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="5" md="6" class="pt-0">
              <label class="ml-5"></label>
              <v-select
                class="mt-2 auto-line-row"
                required
                :items="businessTypeItems"
                item-text="name"
                return-object
                label="選択して下さい"
                @change="handlerDataFiled"
                v-model="vModelDataFormCorporate.businessType"
                hide-details="auto"
                solo
                :rules="businessTypeRules"
              />
            </v-col>
            <v-col cols="12" sm="7" md="6" class="pt-0 d-box-other-input">
              <label class="font-label-sp">その他と選択された方</label>
              <v-text-field
                required
                class="mt-2 mr-6"
                label=""
                :disabled="!isBusinessTypeOther"
                hide-details="auto"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.businessTypeOther"
                :rules="businessTypeOtherRules"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">ご担当者名</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">{{ '例：東急 太郎（全角）' }}</label>
          </div>
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                required
                label=""
                class="mr-6"
                hide-details="auto"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.companyPicName"
                :rules="companyPicNameRules"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">フリガナ</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">{{ '例：トウキュウ タロウ（全角）' }}</label>
          </div>
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                required
                label=""
                class="mr-6"
                hide-details="auto"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.companyPicKana"
                :rules="companyPicKanaRules"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">ご担当者部署・役職名</span>
          <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
            {{ $t('common.labelOptional') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="6" md="6">
              <label class="font-label-sp">例：営業部</label>
              <v-text-field
                class="mt-2 mr-6"
                label=""
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.companyPicDepartment"
                :rules="companyPicDepartmentRules"
                hide-details="auto"
                solo />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <label class="font-label-sp">例：部長</label>
              <v-text-field
                class="mt-2 mr-6"
                label=""
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.companyPicTitle"
                :rules="companyPicTitleRules"
                hide-details="auto"
                solo />
            </v-col>
            </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">社員数</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row d-box-company-size">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">例：50（半角）</label>
          </div>
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="5" md="3" class="d-col-company-size">
              <v-text-field
                required
                class=""
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.companySize"
                :rules="companySizeRules"
                label=""
                minLength="1"
                maxLength="8"
                hide-details="auto"
                solo />
            </v-col>
            <v-col class="ml-0 pl-0 mr-2">
              <span class="ma-0 pa-0 mt-5 d-inline-flex">
                名
                <span class="txt-guild-company-size">（※おおよその人数でも結構です）</span></span>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <span class="txt-guild-company-size-sp font-label-sp">（※おおよその人数でも結構です）</span>
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">郵便番号（連絡先）</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">{{ $t('common.postalCodeExample') }}</label>
          </div>
          <v-row class="ml-3 mt-0 mr-3">
            <v-col cols="6" sm="6" md="4">
              <v-text-field
                required
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.postalCode"
                :rules="postCodeRules"
                label=""
                minLength="7"
                maxLength="7"
                hide-details="auto"
                solo />
            </v-col>
            <v-col cols="4" sm="4" md="4" class="ma-0">
              <v-btn class="btn-action-postal-code pa-0 d-flex" title="住所検索" :disabled="!disabledPostalCode" @click="btnPostalCodeCorporate">
                <v-icon x-large right class="mr-3 ml-1">mdi-chevron-right</v-icon>
                住所検索
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-150 bg-main-from d-flex justify-space-between align-center">
          <div class="d-block-pc">
            <div class="d-label-left ml-3">
              <span>ご住所（連絡先）</span>
            </div>
            <v-chip class="cus-here-position d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </div>
        </div>
        <div class="lv1-label-duplicate-row">
          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2">
                <p class="ma-0 pa-0 mt-4">都道府県</p>
              </div>
            </div>
            <div class="d-none-single-row pt-3">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">都道府県</label>
                <label class="font-label-sp">{{ $t('common.prefectureExample') }}</label>
              </div>
              <v-col cols="12" sm="6" md="6" class="ml-3">
                <v-select
                  required
                  :items="prefectureItems"
                  item-text="name"
                  return-object
                  label="選択して下さい"
                  @change="handlerDataFiled"
                  v-model="vModelDataFormCorporate.prefecture"
                  :rules="prefectureRules"
                  hide-details="auto"
                  solo
                >
                  <template v-slot:append>
                    <v-img width="" height="" class="ma-0 pa-0"
                           src="@/assets/images/icon-arrow-down.svg"
                           contain
                    />
                  </template>
                </v-select>
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">市区郡</label>
                <p class="ma-0 pa-0 mt-4">市区郡</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">市区郡</label>
                <label class="font-label-sp">{{ $t('common.localityExample') }}</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  label=""
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataFormCorporate.cityOrCounty"
                  :rules="cityOrCountyRules"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2">
                <p class="ma-0 pa-0 mt-4">町名・番地</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">町名・番地</label>
                <label class="font-label-sp">{{ $t('common.streetAddressExample') }} </label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataFormCorporate.streetBunch"
                  label=""
                  :rules="streetBunchRules"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2 ">
                <p class="ma-0 pa-0 mt-4">ビル名・マンション名等</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">ビル名・マンション名等</label>
                <label class="font-label-sp">{{ $t('common.buildingNameExample') }}</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataFormCorporate.addressOther"
                  label=""
                  :rules="addressOtherRules"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-150 bg-main-from d-flex justify-space-between align-center">
          <div class="d-block-pc">
            <div class="d-box-title-pc">
              <p class="d-label-left ml-3">ご連絡先</p>
              <p class="ma-0 ml-4">* どちらか1つは</p>
              <p class="ma-0 ml-7"> 入力してください</p>
            </div>
            <v-chip class="cus-here-position d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </div>
        </div>
        <div class="lv1-label-duplicate-row">
          <v-row class="ma-0">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">電話番号</label>
                <p class="ma-0 pa-0 mt-4">電話番号</p>
              </div>
            </div>
            <div class="d-none-single-row mt-4">
              <div class="ml-6 mr-6">
                <label class="font-label-sp">{{ $t('common.telExample') }}</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  class="mr-6"
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataFormCorporate.homePhoneNumber"
                  label=""
                  :rules="[...homePhoneNumberRules, ...phoneNumberCorporateRules]"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2">
                <p class="ma-0 pa-0 mt-4">携帯電話番号</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">携帯電話番号</label>
                <label class="font-label-sp">例：090-1234-5678 （半角）</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  class="mr-6"
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataFormCorporate.mobileNumber"
                  label=""
                  :rules="[...mobileNumberRules, ...phoneNumberCorporateRules]"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">連絡先<br>メールアドレス</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">例：tokyu_taro@tokyu-sharing.co.jp（半角）</label>
          </div>
          <v-row class="ml-3 mt-0 mr-3">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                required
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.email"
                label=""
                :rules="emailRules"
                hide-details="auto"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <div class="d-label-left ml-3">
            <label>今回問い合わせた
              きっかけ</label>
          </div>
          <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
            {{ $t('common.labelOptional') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                class="d-textarea mr-6"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataFormCorporate.inquiry"
                hide-details
                rows="3"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <div class="d-label-left ml-3">
            <label>働き方改革に <br>取り組んでいる</label>
          </div>
          <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
            {{ $t('common.labelOptional') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <v-row class="ml-3 mt-0">
            <v-col cols="12" sm="2" md="2" class="pt-0" v-for="item in workStyleReformItems" :key="item.value">
              <v-checkbox
                :value="item"
                class="ma-0"
                v-model="vModelDataFormCorporate.workStyleReform"
                hide-details
                :label="item.text"
              />
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import {
  isEmailValid,
  isHomePhoneNumberValid,
  isKanaValid,
  isMobileNumberValid,
  isPostCodeValid
} from '@/utils/validators'
import C from '@/utils/constant'

export default {
  data () {
    return {
      isValid: true,
      userNameLabel: '例：東急　太郎（全角）',
      companyNameRules: [
        value => !!value || this.$t('rules.required', { title: this.$t('rules.data.companyName') })
      ],
      kanaCompanyNameRules: [
        value => !!value || this.$t('rules.required', { title: this.$t('rules.data.companyNameKana') }),
        v => isKanaValid(v) || this.$t('rules.katakana', { title: this.$t('rules.data.companyNameKana') })
      ],
      userNameRules: {
        lastName: [
          value => !!value || this.$t('rules.required', { title: this.$t('rules.data.lastName') })
        ],
        firstName: [
          value => !!value || this.$t('rules.required', { title: this.$t('rules.data.firstName') })
        ]
      },
      emailRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.email') }),
        v => isEmailValid(v) || this.$t('rules.invalid', { title: this.$t('rules.data.email') })
      ],
      cityOrCountyRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.cityOrCounty') })
      ],
      streetBunchRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.streetBunch') })
      ],
      addressOtherRules: [
        true
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.addressOther') })
      ],
      homePhoneNumberRules: [
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.homePhoneNumber') }),
        v => ((!!v && isHomePhoneNumberValid(v)) || !v) || this.$t('rules.corporateNumberValid')
      ],
      mobileNumberRules: [
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.mobileNumber') }),
        v => ((!!v && isMobileNumberValid(v)) || !v) || this.$t('rules.mobileNumberValid')
      ],
      postCodeRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.postalCode') }),
        v => isPostCodeValid(v) || this.$t('rules.postCodeValid')
      ],
      companySizeRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.companySize') }),
        v => !isNaN(v) || this.$t('rules.invalid', { title: this.$t('rules.data.companySize') }),
        v => /^[0-9]+$/.test(v) || this.$t('rules.invalid', { title: this.$t('rules.data.companySize') })
      ],
      companyPicDepartmentRules: [
        true
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.companyPicDepartment') })
      ],
      companyPicTitleRules: [
        true
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.companyPicTitle') })
      ],
      companyPicNameRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.companyPicName') })
      ],
      companyPicKanaRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.companyPicKana') }),
        v => isKanaValid(v) || this.$t('rules.katakana', { title: this.$t('rules.data.companyPicKana') })
      ],
      prefectureRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.prefecture') }),
        v => (v && Object.keys(v).length > 0) || this.$t('rules.required', { title: this.$t('rules.data.prefecture') })
      ],
      businessTypeRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.businessType') })
      ],
      vModelDataFormCorporate: {
        companyName: '',
        companyNameKana: '',
        businessType: null,
        businessTypeOther: '',
        companyPicName: '',
        companyPicKana: '',
        companyPicDepartment: '',
        companyPicTitle: '',
        companySize: '',
        postalCode: '',
        prefecture: {},
        cityOrCounty: '',
        streetBunch: '',
        addressOther: '',
        homePhoneNumber: '',
        mobileNumber: '',
        email: '',
        inquiry: '',
        workStyleReform: ''
      },
      workStyleReformItems: []
    }
  },
  model: {
    event: ['eventChangeDataDocReq']
  },
  props: {
    dataModel: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.initEnumList()
    this.workStyleReformItems = this.initWorkStyleReform()

    if (this.dataModel && Object.keys(this.dataModel).length) {
      this.vModelDataFormCorporate = this.dataModel
    }
  },
  methods: {
    initWorkStyleReform () {
      return [{
        text: 'はい',
        value: this.constant.WORK_STYLE_REFORM_TRUE
      }, {
        text: 'いいえ',
        value: this.constant.WORK_STYLE_REFORM_FALSE
      }]
    },
    initEnumList () {
      this.$store.dispatch('fetchEnumPrefectureListDispatch', { apolloClient: this.$apollo, toast: this.$toast })
      this.$store.dispatch('fetchEnumBusinessTypeListDispatch', { apolloClient: this.$apollo, toast: this.$toast })
    },
    handlerDataFiled () {
      this.eventDataPush()
    },
    eventDataPush () {
      this.$nextTick(() => {
        this.$emit('eventChangeDataDocReq', {
          data: this.vModelDataFormCorporate,
          isValid: this.isValid
        })
      })
    },
    btnPostalCodeCorporate () {
      const self = this
      if (self.vModelDataFormCorporate?.postalCode.length === 7) {
        self.$store.dispatch('fetchPostalCodeDispatch', {
          payload: self.vModelDataFormCorporate.postalCode,
          totast: self.$toast
        }).then(async data => {
          if (data) {
            if (data?.address1 && self.prefectureItems && Array.isArray(self.prefectureItems)) {
              const regex = new RegExp(`${data.address1}`)
              const find = self.prefectureItems.find(ele => regex.test(ele.name))
              if (find) {
                self.vModelDataFormCorporate.prefecture = find
              }
            }
            if (data?.address2) {
              self.vModelDataFormCorporate.cityOrCounty = data.address2
            }
            if (data?.address3) {
              self.vModelDataFormCorporate.streetBunch = data.address3
            }
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      enumPrefectures: state => state.main.enumPrefectures,
      enumBusinessTypeList: state => state.main.enumBusinessTypeList
    }),
    constant () {
      return Object.assign({}, C)
    },
    prefectureItems () {
      return this.enumPrefectures
    },
    businessTypeItems () {
      return this.enumBusinessTypeList
    },
    phoneNumberCorporateRules () {
      return [
        (`${this.vModelDataFormCorporate.homePhoneNumber}`.length > 0 || `${this.vModelDataFormCorporate.mobileNumber}`.length > 0) || this.$t('rules.invalid', { title: this.$t('rules.data.phoneNumber') })
      ]
    },
    isBusinessTypeOther () {
      return (this.vModelDataFormCorporate?.businessType && this.vModelDataFormCorporate?.businessType?.name && !!this.vModelDataFormCorporate?.businessType?.name.length && /その他/.test(this.vModelDataFormCorporate?.businessType?.name))
    },
    businessTypeOtherRules () {
      return [
        (!this.isBusinessTypeOther || (this.isBusinessTypeOther && this.vModelDataFormCorporate?.businessTypeOther && !!this.vModelDataFormCorporate?.businessTypeOther.length)) || this.$t('rules.required', { title: this.$t('rules.data.businessTypeOther') })
      ]
    },
    disabledPostalCode () {
      return this.vModelDataFormCorporate.postalCode && !!this.vModelDataFormCorporate.postalCode.length
    }
  },
  watch: {
    // watch for change and then validate
    values: 'validate'
  }
}
</script>

<style lang="scss">
.d-row-from {
  border: 1px solid #CDD6DD;

  .d-with-ymd-child {
     .v-input__control .v-text-field__details {
       padding-left: 0;
       padding-right: 0;
     }
  }
}
.d-textarea {
  resize: none;
}
.d-box-company-size {
}

</style>
