<template>
  <div class="document-request-mfr-show-step2">
    <v-card class="mt-30">
      <v-card-text class="ma-0 pa-0">
        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">会社名</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.companyName || '' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">会社名フリガナ</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.companyNameKana || '' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">業種</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">
              {{ mainCorporateCustomer.businessType.name || '' }}
              <span v-if="mainCorporateCustomer.businessTypeOther">
                ({{ mainCorporateCustomer.businessTypeOther }})
              </span>
            </p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">ご担当者名</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.companyPicName || '' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">フリガナ</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.companyPicKana || '' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">ご担当者部署・役職名</span>
            <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
              {{ $t('common.labelOptional') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.companyPicDepartment || '-' }} / {{ mainCorporateCustomer.companyPicTitle || '-' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">社員数</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.companySize || '' }}名</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">郵便番号（連絡先）</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.postalCode || '' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">ご住所（連絡先）</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10">
            <v-row class="bd-from-bottom">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">都道府県</span>
              </v-col>
              <v-col md="10" sm="10" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ prefecture || '' }}</p>
              </v-col>
            </v-row>

            <v-row class="bd-from-bottom">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">市区郡</span>
              </v-col>
              <v-col md="10" sm="10" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.cityOrCounty || '' }}</p>
              </v-col>
            </v-row>

            <v-row class="bd-from-bottom">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">町名・番地</span>
              </v-col>
              <v-col md="10" sm="10" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.streetBunch || '' }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">ビル名・マンション名等</span>
              </v-col>
              <v-col md="10" sm="10" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.addressOther || '' }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center pt-0 pb-0">
            <span class="d-label-left">ご連絡先</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" :class="['']">
            <v-row :class="[{'bd-from-bottom': mainCorporateCustomer.mobileNumber}]" v-if="mainCorporateCustomer.homePhoneNumber">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">電話番号</span>
              </v-col>
              <v-col md="10" sm="10" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.homePhoneNumber || '' }}</p>
              </v-col>
            </v-row>

            <v-row v-if="mainCorporateCustomer.mobileNumber">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">携帯電話番号</span>
              </v-col>
              <v-col md="10" sm="10" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.mobileNumber || '' }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">メールアドレス</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.email || '' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0" v-if="mainCorporateCustomer.inquiry ">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">今回問い合わせたきっかけ</span>
            <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
              {{ $t('common.labelOptional') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="">
            <p class="ma-0"><format-span :value="mainCorporateCustomer.inquiry" /></p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0" v-if="hasWorkStyleReform">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">働き方改革に <br> 取り組んでいる</span>
            <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
              {{ $t('common.labelOptional') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainCorporateCustomer.workStyleReform.text || '' }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import C from '@/utils/constant'
import { mapState } from 'vuex'
import createWebRequest from '@/mixins/createWebRequest'
import FormatSpan from '../../common/FormatSpan.vue'

export default {
  components: { FormatSpan },
  name: 'MainFormRequestShow',
  mixins: [
    createWebRequest
  ],
  data () {
    return {}
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
    }),
    constant () {
      return Object.assign({}, C)
    },
    userNameFull () {
      return `${this.mainCorporateCustomer?.userLastName} ${this.mainCorporateCustomer?.userFirstName}`
    },
    kanaNameFull () {
      return `${this.mainCorporateCustomer?.kanaLastName} ${this.mainCorporateCustomer?.kanaFirstName}`
    },
    prefecture () {
      return `${this.mainCorporateCustomer?.prefecture?.name}`
    },
    hasWorkStyleReform () {
      return this.mainCorporateCustomer.workStyleReform && Object.keys(this.mainCorporateCustomer.workStyleReform).length
    }
  },
  created () {
    this.typeApply = this.type
  }
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
